import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { resetAll } from "src/app/actions";

export interface IState {
  failedQueries: number;
}

const initialState: IState = {
  failedQueries: 0,
};

const querySlice = createSlice({
  name: "query",
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setFailedQueries: (state, action: PayloadAction<number>) => {
      state.failedQueries = action.payload;
    },
  },
});

export const { setFailedQueries } = querySlice.actions;
export default querySlice.reducer;
