import { IDIDGetFilter } from "src/types/IDid";

export default {
  login: () => "accounts/token/",
  loginContact: () => "validation-code/login/",
  loginContactLanguage: (id: string) => `validation-code/login/${id}`,
  loginMfa: () => "two-factor/login/",
  setupMfa: () => "two-factor-setup/send-code/",
  validateMfaSetup: (id) => `two-factor-setup/validate-code/${id}/`,
  transactionSendCode: (id) => `contact/transactions/${id}/send-code/`,
  fileTransferSendCode: (id) => `contact/file-transfers/${id}/send-code/`,
  recipientValidateCode: (id) => `recipient/validate-code/${id}`,
  contactSendTransactionCode: (id) => `contact/transactions/${id}/send-code/`,
  contactSendSignatureRequestCode: (id) =>
    `signee/signature-request-wave-participation/${id}/send-code/`,
  refreshAccessToken: () => "accounts/token/refresh/",
  authUser: () => "accounts/self/",
  contacts: () => "contacts/",
  contact: (id: string) => `contacts/${id}/`,
  contactPending: (id: string) => `contacts/${id}/pending/`,
  contactFiles: (id: string) => `contacts/${id}/files/`,
  contactSync: (id: string) => `contacts/${id}/sync/`,
  conversations: () => "conversations/",
  conversation: (id: string) => `conversations/${id}/`,
  conversationAttachments: (id: string) => `conversations/${id}/attachments/`,
  conversationEvents: () => "conversation-events/",
  resolveAfterConversationEvent: (id: string) =>
    `conversation-events/${id}/resolve-after/`,
  memberships: () => "memberships/",
  membership: (id: string) => `memberships/${id}/`,
  selectMembership: (id: string) => `memberships/${id}/select/`,
  participation: (id: string) => `participations/${id}/`,
  teams: () => "teams/",
  team: (id: string) => `teams/${id}/`,
  teamEquisoftEventTypes: (id: string) =>
    `teams/${id}/update_equisoft_event-types/`,
  createTeamDocumentTree: (id: string) => `teams/${id}/create-document-types/`,
  archiveConversation: () => "/conversation-archives/",
  contactConversationArchive: (id: string) =>
    `contacts/${id}/conversation-archives/`,
  contactConversationArchiveDetail: (contactId: string, archiveId: string) =>
    `contacts/${contactId}/conversation-archives/${archiveId}/`,
  getConversationArchiveAsContact: (id) =>
    `contact/conversation-archives/${id}`,
  facebookAuth: () => "facebook/onboarding/",
  sharepointAuth: () => "sharepoint/auth/",
  sharepointSites: () => "sharepoint/sites/",
  sharepointSite: (siteId) => `sharepoint/sites/${siteId}`,
  sharepointTenant: () => "sharepoint/tenant/",
  getDids: (filter: IDIDGetFilter) =>
    `did/new/?npa=${filter.npa}&nxx=${filter.nxx}`,
  didSubmit: () => "did/new/",
  getDid: () => "did/list/",
  equisoftConnect: () => "equisoft-auth/",
  equisoftConnectMe: () => "equisoft-auth/me/",
  equisoftDocuments: () => "equisoft-documents/",
  transferMediumConfig: (id: string) => `medium-configs/${id}/transfer/`,
  getEventTypes: () => "/event-types/",
  getDocumentTypes: () => "/document-types/",
  registration: () => "registration/",
  invitations: () => "team-invitations/",
  invitation: (key: string) => `team-invitations/${key}/`,
  resendInvitation: (key: string) => `team-invitations/${key}/resend/`,
  acceptInvitation: (key: string) => `team-invitations/${key}/accept/`,
  transactions: () => "transactions/",
  transaction: (id: string) => `transactions/${id}/`,
  resendTransaction: (id: string) => `transactions/${id}/resend/`,
  getTransactionAsContact: (transaction_id) =>
    `contact/transactions/${transaction_id}/`,
  updateTransactionAsContact: (transaction_id) =>
    `contact/transactions/${transaction_id}/`,
  getTransactionAsRecipient: (id: string) => `recipient/transactions/${id}/`,
  signatureRequests: () => "signature-requests/",
  signatureRequest: (id: string) => `signature-request/${id}/`,
  signatureRequestDuplicate: (id: string) =>
    `signature-request/${id}/duplicate/`,
  signatureRequestComplete: (id: string) =>
    `signature-request/${id}/send-first-wave/`,
  signatureRequestFiles: (id: string) => `signature-request/${id}/files/`,
  signatureRequestFile: (signature_request_id: string, id: string) =>
    `signature-request/${signature_request_id}/files/${id}/`,
  signatureRequestFileFields: (signature_request_file_id: string) =>
    `signature-request-files/${signature_request_file_id}/fields/`,
  signatureRequestFileField: (signature_request_file_id: string, id: string) =>
    `signature-request-files/${signature_request_file_id}/fields/${id}/`,
  signatureRequestWaves: (signature_request_id: string) =>
    `signature-request/${signature_request_id}/signature-wave/`,
  signatureRequestWave: (signature_request_id: string, id: string) =>
    `signature-request/${signature_request_id}/signature-wave/${id}/`,
  signatureRequestWaveSend: (id: string) =>
    `signature-request/${id}/resend-current-wave/`,
  signatureRequestWaveParticipations: (signature_request_wave_id: string) =>
    `signature-wave/${signature_request_wave_id}/contact/`,
  signatureRequestWaveMembershipParticipations: (
    signature_request_wave_id: string,
  ) => `signature-wave/${signature_request_wave_id}/advisor/`,
  signatureRequestWaveRecipientParticipations: (
    signature_request_wave_id: string,
  ) => `signature-wave/${signature_request_wave_id}/recipient/`,
  signatureRequestWaveRecipientParticipation: (
    signature_request_wave_id: string,
    participation_id,
  ) =>
    `signature-wave/${signature_request_wave_id}/recipient/${participation_id}/`,
  signatureRequestWaveExternalParticipations: (
    signature_request_wave_id: string,
  ) => `signature-wave/${signature_request_wave_id}/external/`,
  signatureRequestWaveParticipation: (
    signature_request_wave_id: string,
    id: string,
  ) => `signature-wave/${signature_request_wave_id}/participation/${id}/`,
  unlockSignatureRequestWaveParticipation: (id: string) =>
    `signee/signature-request-wave-participation/${id}/unlock/`,
  signatureRequestWaveParticipationAsSignee: (
    signature_request_wave_id: string,
    id: string,
  ) =>
    `signature-wave/${signature_request_wave_id}/participation/${id}/signee/`,
  signatureRequestWaveParticipationMail: (id: string) =>
    `signee/signature-request-wave-participation/${id}/send-mail/`,
  signatureRequestByIdAsSignee: (id: string, participation_id: string) =>
    `signee/signature-request/${id}/participations/${participation_id}/`,
  signatureRequestByIdConsentAsSignee: (id: string, participation_id: string) =>
    `signee/signature-request/${id}/participations/${participation_id}/consent/`,
  signatureRequestByIdAsSigneeDownload: (
    id: string,
    participation_id: string,
  ) =>
    `signee/signature-request/${id}/participations/${participation_id}/download/`,
  signatureRequestFileFieldsAsSignee: (signature_request_file_id: string) =>
    `signee/signature-request-files/${signature_request_file_id}/fields/`,
  signatureRequestFileFieldAsSignee: (
    signature_request_file_id: string,
    id: string,
  ) =>
    `signee/signature-request-files/${signature_request_file_id}/fields/${id}/`,
  signatureRequestFilesAsSignee: (id: string, participation_id: string) =>
    `signee/signature-request/${id}/participations/${participation_id}/signature-request-files/`,
  signatureRequestFileAsSignee: (id: string) =>
    `signee/signature-request-files/${id}/`,
  signatureRequestArchive: (id: string) => `signature-request/${id}/archive/`,
  signatureRequestArchiveDocuments: (signature_request_id: string) =>
    `signature-request/${signature_request_id}/archive/documents/`,
  signatureRequestArchiveDocument: (signature_request_id: string, id: string) =>
    `signature-request/${signature_request_id}/archive/documents/${id}/`,
  signatureRequestArchiveSharepointDocuments: (signature_request_id: string) =>
    `signature-request/${signature_request_id}/archive/documents/sharepoint/`,
  signatureRequestArchiveSharepointDocument: (
    signature_request_id: string,
    id: string,
  ) =>
    `signature-request/${signature_request_id}/archive/documents/sharepoint/${id}/`,
  signatureRequestOneSpanIFrame: (id: string) =>
    `signature-request/${id}/onespan/iframe/`,
  signeeSignatureRequestOneSpanIFrame: (id: string) =>
    `signee/signature-request-participation/${id}/onespan/iframe/`,
  recipients: () => "recipients/",
  recipient: (id: string) => `recipients/${id}/`,
  externalArchives: () => "external-archives/",
  externalArchive: (id: string) => `external-archives/${id}/`,
  getExternalArchiveAsRecipient: (id: string) =>
    `recipient/external-archives/${id}/`,
  getFileAsRecipient: (id: string) => `recipient/files/${id}/`,
  fileTransfer: (id: string) => `file-transfers/${id}/`,
  getFileAsContact: (id: string) => `contact/files/${id}/`,
  getPhoneRecordings: () => "phone-recordings/",
  getPhoneRecording: (id: string) => `phone-recordings/${id}/`,
};
