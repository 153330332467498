import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#533589",
      light: "#8a68c6",
      dark: "#1d0743",
      contrastText: "#fff",
    },
    secondary: {
      main: "#1d578c",
    },
    background: {
      default: "#f5f4f6",
    },
    success: {
      main: "#41601B",
    },
    error: {
      main: "#D32F2F",
    },
    grey: {
      100: "#f5f4f6",
      200: "#e6e6e8",
      500: "#9d93ae",
    },
  },
  typography: {
    fontFamily: "Rubik",
    h2: {
      fontWeight: 400,
      fontSize: "2rem",
    },
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiPaper: {
      defaultProps: {
        elevation: 3,
      },
      styleOverrides: {
        root: {
          borderRadius: "0.5rem",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: process.env.NODE_ENV === "test",
      },
    },
    MuiInputBase: {
      defaultProps: {
        sx: {
          backgroundColor: "white",
        },
      },
    },
  },
});
