import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { resetAll } from "src/app/actions";

export interface ITeamNotification {
  teamId: string;
  count: number;
}

export interface IState {
  drawerIsOpen: boolean;
  teamSocket: WebSocket | null;
  teamsNotification: ITeamNotification[];
}

const initialState: IState = {
  drawerIsOpen: false,
  teamSocket: null,
  teamsNotification: [],
};

const teamSlice = createSlice({
  name: "teams",
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setTeamDrawer: (state, action: PayloadAction<boolean>) => {
      state.drawerIsOpen = action.payload;
    },

    setTeamsNotification: (
      state,
      action: PayloadAction<ITeamNotification[]>,
    ) => {
      state.teamsNotification = action.payload;
    },

    decrementByAmoutTeamsNotification: (
      state,
      action: PayloadAction<{ teamId: string; amount: number }>,
    ) => {
      state.teamsNotification.forEach((team, index) => {
        if (team.teamId === action.payload.teamId) {
          state.teamsNotification[index].count -= action.payload.amount;
        }
      });
    },

    setTeamNotificationByTeamId: (
      state,
      action: PayloadAction<{ teamId: string; count: number }>,
    ) => {
      state.teamsNotification.forEach((team, index) => {
        if (team.teamId === action.payload.teamId) {
          state.teamsNotification[index].count = action.payload.count;
        }
      });
    },
  },
});

export const {
  setTeamDrawer,
  setTeamsNotification,
  decrementByAmoutTeamsNotification,
  setTeamNotificationByTeamId,
} = teamSlice.actions;
export default teamSlice.reducer;
