import { createSlice } from "@reduxjs/toolkit";

import { resetAll } from "src/app/actions";

const initialState = {
  shouldPollTeam: false,
};

export const pollingSlice = createSlice({
  name: "polling",
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setShouldPoll: (state, action) => {
      state.shouldPollTeam = action.payload;
    },
  },
});

export const { setShouldPoll } = pollingSlice.actions;

export default pollingSlice.reducer;
