import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { resetAll } from "src/app/actions";

export interface IChatSliceState {
  inputValues: Array<{ conversationId: string; value: string }>;
  chatDrawerIsOpen: boolean;
}

const initialState: IChatSliceState = {
  inputValues: [],
  chatDrawerIsOpen: false,
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setChatDrawerIsOpen: (state, action: PayloadAction<boolean>) => {
      state.chatDrawerIsOpen = action.payload;
    },

    setInputValues: (
      state,
      action: PayloadAction<{ conversationId: string; value: string }>,
    ) => {
      const itContainsConversationInputvalue = state.inputValues.some(
        (inputValue) =>
          inputValue.conversationId === action.payload.conversationId,
      );

      if (!itContainsConversationInputvalue) {
        state.inputValues = [...state.inputValues, action.payload];
      }

      if (itContainsConversationInputvalue) {
        state.inputValues.forEach((inputValue, index) => {
          if (inputValue.conversationId === action.payload.conversationId) {
            state.inputValues[index].value = action.payload.value;
          }
        });
      }
    },
  },
});

export const { setInputValues, setChatDrawerIsOpen } = chatSlice.actions;
export default chatSlice.reducer;
