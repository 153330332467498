import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { resetAll } from "src/app/actions";

interface IPhoneRecordingSliceState {
  selectedPhoneRecordingId: string;
}

const initialState: IPhoneRecordingSliceState = {
  selectedPhoneRecordingId: "",
};

const PhoneRecordingSlice = createSlice({
  name: "PhoneRecording",
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setSelectedPhoneRecordingId: (state, action: PayloadAction<string>) => {
      state.selectedPhoneRecordingId = action.payload;
    },
  },
});

export const { setSelectedPhoneRecordingId } = PhoneRecordingSlice.actions;
export default PhoneRecordingSlice.reducer;
