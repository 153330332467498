import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { resetAll } from "src/app/actions";

export interface IState {
  fileDrawerIsOpen: boolean;
}

const initialState: IState = {
  fileDrawerIsOpen: false,
};

const sharedFileSectionSlice = createSlice({
  name: "sharedFileSection",
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setSharedFileDrawerOpened: (state, action: PayloadAction<boolean>) => {
      state.fileDrawerIsOpen = action.payload;
    },
  },
});

export const { setSharedFileDrawerOpened } = sharedFileSectionSlice.actions;
export default sharedFileSectionSlice.reducer;
