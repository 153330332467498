import { configureStore, PreloadedState } from "@reduxjs/toolkit";

import { api } from "src/services/store/apiSlice";
import { errorMiddleware } from "src/services/errorMiddleware";

import rootReducer from "./rootReducer";

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(api.middleware, errorMiddleware),
  });
};

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
