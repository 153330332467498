import { IMembership, IPartialTeam, IPartialUser } from "src/types";

export const LOCALSTORAGE_TNS_SELECTED_MEDIUM = "selectedMedium";
export const LOCALSTORAGE_TNS_CHAT_INPUT_VALUE = "tnsChatInputValue";
export const CHAT_SOCKET_URL =
  `${process.env.VITE_APP_SOCKET_URL}conversations/` || "";
export const USER_SOCKET_URL = `${process.env.VITE_APP_SOCKET_URL}users/` || "";
export const REFRESH_SOCKET_URL = `${process.env.VITE_APP_SOCKET_URL}refresh/`;
export const REFRESH_CONTACT_URL = `${process.env.VITE_APP_SOCKET_URL}refresh-contact/`;
export const ROOT_URL = process.env.VITE_APP_ROOT_URL || "";
export const NO_ASSIGNED_USER: IPartialUser = {
  id: "no-assigned-user",
  first_name: "Non-Assigné",
  last_name: "",
  email: "",
};
export const IPARTIAL_TEAM_DEFAULT: IPartialTeam = {
  id: "",
  name: "",
  logo: "",
  language: "",
};
export const NO_ASSIGNED_MEMBERSHIP: IMembership = {
  id: "no-assigned-membership",
  type: "advisor",
  user: NO_ASSIGNED_USER,
  created: "",
  modified: "",
  team: IPARTIAL_TEAM_DEFAULT,
  phone: "4185554444",
};
export const EQUISOFT_CONNECTION_FAILED =
  "Equisoft/Connect authentication failed";
export const EQUISOFT_CONNECTION_SUCCEEDED =
  "Equisoft/Connect authentication completed";
export const EQUISOFT_REDIRECT_URL =
  process.env.VITE_APP_EQUISOFT_REDIRECT_URL || "";
export const DEBUG_I18NEXT = process.env.VITE_APP_DEBUG_I18NEXT === "true";
