import { isRejectedWithValue } from "@reduxjs/toolkit";
import type { Middleware } from "@reduxjs/toolkit";
import { t } from "i18next";
import { toast } from "react-toastify";

export const errorMiddleware: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (action.payload.status >= 500) {
      if (
        action.payload.data.displayable &&
        action.payload.data.non_field_errors
      ) {
        toast.error(action.payload.data.non_field_errors);
      } else {
        toast.error(t("serverErrorOccurred", { ns: "common" }));
      }
    }
  }

  return next(action);
};
