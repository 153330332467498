import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { resetAll } from "src/app/actions";
import { RootState } from "src/app/store";
import {
  AssigneeFilter,
  IConversation,
  ISelectedConversation,
} from "src/types";

export interface INotificationState {
  conversationId: string;
  count: number | null;
  last_message: string;
  last_message_type: string;
  last_message_medium: string;
  last_message_sent_by: string;
  isRead: boolean;
}

export interface IConversationsState {
  filteredConversations: Array<IConversation>;
  selectedConversation: ISelectedConversation | null;
  drawerIsOpen: boolean;
  conversationInputValue: { value: string; userIsSearching: boolean };
  assigneeFilters: AssigneeFilter[];
  unarchivedFilter: boolean;
  unresolvedFilter: boolean;
  page: number;
  isFilterApplied: boolean;
  conversationNotification: Array<INotificationState>;
}

export const initialState: IConversationsState = {
  filteredConversations: [],
  selectedConversation: null,
  drawerIsOpen: true,
  conversationInputValue: { value: "", userIsSearching: false },
  assigneeFilters: [],
  unarchivedFilter: false,
  unresolvedFilter: false,
  page: 1,
  isFilterApplied: true,
  conversationNotification: [],
};

const conversationsSlice = createSlice({
  name: "conversations",
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setDrawerOpened: (state, action: PayloadAction<boolean>) => {
      state.drawerIsOpen = action.payload;
    },

    setFilteredConversations: (
      state,
      action: PayloadAction<IConversationsState["filteredConversations"]>,
    ) => {
      state.filteredConversations = action.payload;
    },

    setSelectedConversation: (
      state,
      action: PayloadAction<IConversationsState["selectedConversation"] | null>,
    ) => {
      state.selectedConversation = action.payload;
    },

    resetSelectedConversation: (state) => {
      state.selectedConversation = null;
    },

    setConversationInputValue: (state, action: PayloadAction<string>) => {
      state.conversationInputValue.value = action.payload;
      state.isFilterApplied = action.payload === "";
      state.page = 1;
    },

    setConversationUserIsSearching: (state, action: PayloadAction<boolean>) => {
      state.conversationInputValue.userIsSearching = action.payload;
    },

    setIsFilterApplied: (state, action: PayloadAction<boolean>) => {
      state.page = 1;
      state.isFilterApplied = action.payload;
    },

    setAssigneeFilters: (state, action: PayloadAction<AssigneeFilter[]>) => {
      state.page = 1;
      state.assigneeFilters = action.payload;
    },

    setUnarchivedFilter: (state, action: PayloadAction<boolean>) => {
      state.page = 1;
      state.unarchivedFilter = action.payload;
    },

    setUnresolvedFilter: (state, action: PayloadAction<boolean>) => {
      state.page = 1;
      state.unresolvedFilter = action.payload;
    },

    increasePage: (state) => {
      state.page += 1;
    },

    setConversationNotification: (
      state,
      action: PayloadAction<INotificationState[]>,
    ) => {
      state.conversationNotification = action.payload;
    },

    setLastMessage: (
      state,
      action: PayloadAction<{
        conversationId: string;
        lastMessage: string;
        lastMessageType: string;
        lastMessageMedium: string;
        lastMessageSentBy: string;
      }>,
    ) => {
      const {
        conversationId,
        lastMessage,
        lastMessageType,
        lastMessageMedium,
        lastMessageSentBy,
      } = action.payload;
      state.conversationNotification.forEach((element, index) => {
        if (element.conversationId === conversationId) {
          state.conversationNotification[index].last_message = lastMessage;
          state.conversationNotification[index].last_message_type =
            lastMessageType;
          state.conversationNotification[index].last_message_medium =
            lastMessageMedium;
          state.conversationNotification[index].last_message_sent_by =
            lastMessageSentBy;
        }
      });
    },

    setIsRead: (state, action: PayloadAction<string>) => {
      state.conversationNotification.forEach((element, index) => {
        if (element.conversationId === action.payload) {
          state.conversationNotification[index].isRead = true;
        }
      });
    },

    setIsUnread: (state, action: PayloadAction<string>) => {
      state.conversationNotification.forEach((element, index) => {
        if (element.conversationId === action.payload) {
          state.conversationNotification[index].isRead = false;
        }
      });
    },

    setConversationNotificationByConversationId: (
      state,
      action: PayloadAction<{ conversationId: string; count: number }>,
    ) => {
      state.conversationNotification.forEach((notification, index) => {
        if (notification.conversationId === action.payload.conversationId) {
          state.conversationNotification[index].count = action.payload.count;
          state.conversationNotification[index].isRead =
            state.conversationNotification[index].count === 0;
        }
      });
    },

    decrementByAmoutConversationNotificationById: (
      state,
      action: PayloadAction<{ conversationId: string; amount: number }>,
    ) => {
      state.conversationNotification.forEach((notification, index) => {
        if (notification.conversationId === action.payload.conversationId) {
          if (state.conversationNotification[index].count !== null) {
            state.conversationNotification[index].count! -=
              action.payload.amount;
          }
        }
      });
    },
  },
});

export const {
  setFilteredConversations,
  setDrawerOpened,
  setSelectedConversation,
  setConversationInputValue,
  setConversationUserIsSearching,
  setAssigneeFilters,
  setUnarchivedFilter,
  setUnresolvedFilter,
  increasePage,
  setIsFilterApplied,
  setConversationNotification,
  setLastMessage,
  setIsRead,
  setIsUnread,
  setConversationNotificationByConversationId,
  resetSelectedConversation,
  decrementByAmoutConversationNotificationById,
} = conversationsSlice.actions;
export const selectConversationNotificationCount = (state: RootState) => {
  let count = 0;
  state.conversations.conversationNotification.forEach((notification) => {
    if (notification.count) count += notification.count;
  });
  return count;
};
export default conversationsSlice.reducer;
