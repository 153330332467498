import { StyledEngineProvider } from "@mui/material/styles";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material";
import { HelmetProvider } from "react-helmet-async";

import reportWebVitals from "./reportWebVitals";
// eslint-disable-next-line import/order
import App from "./app/App";
import "./index.scss";
import "./i18n";

import { theme } from "./theme";

Sentry.init({
  dsn: `${process.env.VITE_APP_SENTRY_DSN}`,
  integrations: [new BrowserTracing()],
  tracesSampleRate: Number(process.env.VITE_APP_SENTRY_TRACES_SAMPLE_RATE),
  environment: process.env.VITE_APP_ENVIRONMENT,
});

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement as HTMLElement);

root.render(
  // <React.StrictMode>
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </ThemeProvider>
  </StyledEngineProvider>,
  // </React.StrictMode>,
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
