import { createApi } from "@reduxjs/toolkit/query/react";

import { customBaseQuery } from "src/services/utils";
import TAGS from "src/services/utils/tags";

export const api = createApi({
  reducerPath: "api",
  baseQuery: customBaseQuery,
  endpoints: () => ({}),
  tagTypes: Object.values(TAGS),
});

export const { reducerPath, reducer } = api;
