import { Box, Stack } from "@mui/material";
import { ReactNode } from "react";

import FullLogo from "src/assets/img/fullLogo.svg?react";

interface IFullPageContainerProps {
  hasLogo?: boolean;
  teamName?: string;
  children: ReactNode;
  logoWidth?: number;
  logoSrc?: string;
  marginBottom?: number;
}

const FullPageContainer = ({
  hasLogo = false,
  teamName,
  children,
  logoWidth = 175,
  logoSrc,
  marginBottom = 4,
}: IFullPageContainerProps) => {
  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      paddingY={2}
    >
      {hasLogo && (
        <Stack spacing={1} mb={marginBottom} alignItems="center">
          {logoSrc ? (
            <img src={logoSrc} alt={teamName} width="150px" />
          ) : (
            <FullLogo width={logoWidth} />
          )}
          {teamName && <h2>{teamName}</h2>}
        </Stack>
      )}
      {children}
    </Box>
  );
};

export default FullPageContainer;
