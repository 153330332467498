// https://www.sohamkamani.com/javascript/localstorage-with-ttl-expiry/
export const setWithExpiryDate = (
  key: string,
  value: string,
  expirationDate: number,
) => {
  const item = { value, expiry: expirationDate };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getWithExpiryDate = (key: string) => {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) return null;

  const item = JSON.parse(itemStr);
  const now = new Date().getTime() / 1000;

  if (now > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
};
