import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ErrorState {
  emptyWaveError: boolean;
  noWaveError: boolean;
  duplicateEmailError: boolean;
  documentNoTypeError: boolean;
  externalParticipationIncompleteError: boolean;
}

const initialState: ErrorState = {
  emptyWaveError: false,
  noWaveError: false,
  duplicateEmailError: false,
  documentNoTypeError: false,
  externalParticipationIncompleteError: false,
};

const signatureRequestErrorSlice = createSlice({
  name: "signatureRequestErrors",
  initialState,
  reducers: {
    setSignatureRequestErrors: (
      state,
      action: PayloadAction<{ type: keyof ErrorState; value: boolean }>,
    ) => {
      state[action.payload.type] = action.payload.value;
    },
    clearAllErrors: (state) => {
      Object.keys(state).forEach((key) => {
        state[key as keyof ErrorState] = false;
      });
    },
  },
});

export const { setSignatureRequestErrors, clearAllErrors } =
  signatureRequestErrorSlice.actions;
export default signatureRequestErrorSlice.reducer;
