import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IUser } from "src/types";
import { IUserEvent } from "src/types/IUserEvent";
import { resetAll } from "src/app/actions";

interface IState {
  userInfo: IUser | null;
  notification: IUserEvent | null;
}

const initialState: IState = {
  userInfo: null,
  notification: null,
};

const userSlice = createSlice({
  initialState,
  name: "userSlice",
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setNotification: (state, action: PayloadAction<IUserEvent>) => {
      state.notification = action.payload;
    },
    resetNotification: (state) => {
      state.notification = null;
    },
  },
});

export const { setNotification, resetNotification } = userSlice.actions;
export default userSlice.reducer;
