import { ComponentPropsWithoutRef, ForwardedRef, forwardRef } from "react";

import Checkmark from "src/assets/img/Checkmark.svg?react";
import "./Checkbox.scss";

export interface ICheckboxProps extends ComponentPropsWithoutRef<"input"> {
  label: string;
  isChecked: boolean;
}

const Checkbox = forwardRef(
  (
    { label, isChecked, ...props }: ICheckboxProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <label
        htmlFor={props.id}
        className={`Checkbox ${props.checked ? "Checkbox__checked" : ""}`}
      >
        <span className="Checkbox__rectangle" />
        <input
          type="checkbox"
          {...props}
          className="Checkbox__input"
          ref={ref}
        />
        {isChecked && <Checkmark className="Checkbox__checkmark" />}
        <span className="Checkbox__label">{label}</span>
      </label>
    );
  },
);

export default Checkbox;
