import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import "./interactiveLoading.scss";

type BackgroundActivityContextType = {
  addComponent: (id: symbol) => void;
  removeComponent: (id: symbol) => void;
  hasBackgroundActivity: boolean;
};

const BackgroundActivityContext = createContext<BackgroundActivityContextType>({
  addComponent: () => {},
  removeComponent: () => {},
  hasBackgroundActivity: false,
});

export const InteractiveLoadingProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [activeComponents, setActiveComponents] = useState<Set<symbol>>(
    () => new Set(),
  );

  const addComponent = useCallback(
    (id: symbol) => {
      if (!activeComponents.has(id)) {
        setActiveComponents((prev) => {
          const updated = new Set(prev);
          updated.add(id);
          return updated;
        });
      }
    },
    [activeComponents],
  );

  const removeComponent = useCallback(
    (id: symbol) => {
      if (activeComponents.has(id)) {
        setActiveComponents((prev) => {
          const updated = new Set(prev);
          updated.delete(id);
          return updated;
        });
      }
    },
    [activeComponents],
  );

  const value = useMemo<BackgroundActivityContextType>(
    () => ({
      addComponent,
      removeComponent,
      hasBackgroundActivity: activeComponents.size > 0,
    }),
    [addComponent, removeComponent, activeComponents],
  );

  return (
    <BackgroundActivityContext.Provider value={value}>
      {children}
    </BackgroundActivityContext.Provider>
  );
};

type HookReturnType = {
  className: string;
  isLoading: boolean;
};

export const useInteractiveLoading = (
  isLoading: boolean,
  classes: string = "",
): HookReturnType => {
  const context = useContext(BackgroundActivityContext);
  const componentId = useMemo(() => Symbol("InteractiveLoading"), []);

  if (!context) {
    throw new Error(
      "useInteractiveLoading must be used within a <BackgroundActivityContext />",
    );
  }

  useEffect(() => {
    if (isLoading) {
      context.addComponent(componentId);
    } else {
      context.removeComponent(componentId);
    }
    // Cleanup
    return () => {
      context.removeComponent(componentId);
    };
  }, [isLoading, componentId, context]);

  const className = `can-interactive-load ${
    isLoading && " currently-loading"
  } ${classes}`;

  return {
    className,
    isLoading,
  };
};

export const useBackgroundActivity = (): boolean => {
  const context = useContext(BackgroundActivityContext);
  return context.hasBackgroundActivity;
};
