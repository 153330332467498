import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { Spinner } from "src/shared";

import Routing from "./Routing";

export const RootSuspense = () => {
  return (
    <div className="CenteredSpinner">
      <Spinner />
    </div>
  );
};

function MainRoutes() {
  return (
    <Router>
      <Suspense fallback={<RootSuspense />}>
        <Routing />
      </Suspense>
    </Router>
  );
}

export default MainRoutes;
