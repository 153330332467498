import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import { forwardRef, ChangeEvent, HTMLAttributes, ReactNode } from "react";
import { Controller, useFormContext } from "react-hook-form";
import ClearIcon from "@mui/icons-material/Clear";

import style from "./formComponent.module.scss";

export interface ICustomTextFieldProps {
  name: string;
  options: any[];
  title: string;
  subtitle?: (option: any) => ReactNode;
  defaultValue?: string;
  isOptionEqualToValue?: (option, value) => boolean;
  multiple?: boolean;
  color?: "purple" | "blue";
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  hasClearIcon?: boolean;
  label?: string;
  isDisabled?: boolean;
  renderOption?: (
    props: HTMLAttributes<HTMLLIElement> & { key: any },
    option: any,
  ) => ReactNode;
  disablePortal?: boolean;
  noOptionsText?: string;
  getOptionLabel?: (option: any) => string;
}

const CustomFormSelect = forwardRef(
  (
    {
      name,
      options,
      title,
      subtitle,
      color = "purple",
      isOptionEqualToValue = (option, value) => option.id === value.id,
      multiple = false,
      onChange,
      hasClearIcon = true,
      label = "",
      isDisabled = false,
      renderOption,
      disablePortal = true,
      noOptionsText,
      getOptionLabel,
    }: ICustomTextFieldProps,
    ref,
  ) => {
    const {
      setValue,
      control,
      formState: { errors },
    } = useFormContext<any>();

    return (
      <div>
        <Controller
          control={control}
          name={name}
          render={({ field: { value } }) => (
            <div>
              <InputLabel
                className={`${style.modalInputLabel} ${style[color]}`}
              >
                {title}
              </InputLabel>
              <Autocomplete
                isOptionEqualToValue={isOptionEqualToValue}
                className={style.chatResolveSelect}
                clearIcon={hasClearIcon && <ClearIcon fontSize="small" />}
                disablePortal={disablePortal}
                options={options}
                multiple={multiple}
                disabled={isDisabled}
                ref={ref}
                onChange={(_, val) => {
                  if (onChange) {
                    onChange(val);
                  }
                  return val ? setValue(name, val) : setValue(name, null);
                }}
                renderInput={(params) => (
                  <TextField
                    helperText={
                      errors[name] ? (errors[name]?.message as string) : ""
                    }
                    placeholder={label}
                    error={!!errors[name]}
                    {...params}
                  />
                )}
                renderOption={
                  subtitle
                    ? (props, option) => (
                        <li {...props}>
                          <div>
                            <strong>
                              {getOptionLabel
                                ? getOptionLabel(option)
                                : option.title}
                            </strong>
                            {subtitle && (
                              <div className={style.subtitle}>
                                {subtitle(option)}
                              </div>
                            )}
                          </div>
                        </li>
                      )
                    : renderOption
                }
                value={value}
                noOptionsText={noOptionsText}
                getOptionLabel={getOptionLabel}
              />
            </div>
          )}
        />
      </div>
    );
  },
);

export default CustomFormSelect;
