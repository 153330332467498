import { useNavigate } from "react-router-dom";

import { useAppDispatch } from "src/app/hooks";
import { api } from "src/services/store/apiSlice";
import { resetAll } from "src/app/actions";

export const useLogout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return () => {
    localStorage.clear();
    dispatch(api.util?.resetApiState());
    dispatch(resetAll());
    navigate("/login");
  };
};
