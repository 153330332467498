import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { decodeToken } from "react-jwt";

import { apiEndpoints } from "src/services/utils";
import { ILoginResult } from "src/types/ILoginResult";
import { IToken } from "src/types/IToken";
import {
  getWithExpiryDate,
  setWithExpiryDate,
} from "src/utils/localStorageUtils";
import { RootState } from "src/app/store";

import { setFailedQueries } from "../store/querySlice";

const baseUrl = process.env.VITE_APP_SERVER_URL;

const baseQuery = fetchBaseQuery({
  prepareHeaders: (headers) => {
    const accessToken = getWithExpiryDate("access_token");

    if (accessToken) {
      headers.set("Authorization", `Bearer ${accessToken}`);
    }

    // headers.set("Content-Type", "application/json");

    return headers;
  },
  baseUrl,
});

const customBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let shouldRedirect = true;
  const { getState, dispatch } = api;
  let {
    query: { failedQueries },
  } = getState() as RootState;

  if (typeof args !== "string") {
    shouldRedirect = args.params?.shouldRedirect !== false;
    delete args.params?.shouldRedirect;
  }

  let result: any = await baseQuery(args, api, extraOptions);

  if (result.error?.status === 401 && shouldRedirect) {
    if (failedQueries > 5) {
      localStorage.clear();
      window.location.href = "/login";
    }

    failedQueries += 1;
    const refreshToken: string = getWithExpiryDate("refresh_token");
    const refreshResult = (await baseQuery(
      {
        body: { refresh: refreshToken },
        method: "POST",
        url: apiEndpoints.refreshAccessToken(),
      },
      api,
      extraOptions,
    )) as QueryReturnValue<ILoginResult>;

    const newAccessToken = refreshResult.data?.access;

    if (newAccessToken) {
      const decodedAccessToken = decodeToken<IToken>(newAccessToken);

      if (!decodedAccessToken) {
        throw new Error("Invalid token");
      }

      setWithExpiryDate("access_token", newAccessToken, decodedAccessToken.exp);
      result = await baseQuery(args, api, extraOptions);
    } else if (window.location.pathname !== "/login") {
      localStorage.clear();
      window.location.href = "/login";
    }
  } else {
    failedQueries = 0;
  }

  dispatch(setFailedQueries(failedQueries));

  return result;
};

export default customBaseQuery;
