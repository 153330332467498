import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { resetAll } from "src/app/actions";

interface INewContactSliceState {
  newContactDrawerIsOpen: boolean;
}

const initialState: INewContactSliceState = {
  newContactDrawerIsOpen: false,
};

const newContactSlice = createSlice({
  name: "newContact",
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setNewContactDrawerIsOpen: (state, action: PayloadAction<boolean>) => {
      state.newContactDrawerIsOpen = action.payload;
    },
  },
});

export const { setNewContactDrawerIsOpen } = newContactSlice.actions;
export default newContactSlice.reducer;
