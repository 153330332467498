import { CSSProperties } from "react";
import { Paper, PaperProps } from "@mui/material";

import style from "./SectionContainer.module.scss";

export type ISectionContainerProps = {
  width?: CSSProperties["width"];
  fullHeight?: boolean;
  alignTop?: boolean;
} & PaperProps;

const SectionContainer = ({
  width,
  fullHeight = false,
  alignTop = false,
  sx,
  children,
  ...props
}: ISectionContainerProps) => {
  const styleProps: PaperProps["sx"] = sx;

  const className = `${style.SectionContainer} ${
    fullHeight ? style.fullHeight : ""
  } ${alignTop ? style.alignTop : ""}`;

  return (
    <Paper sx={{ width, ...styleProps }} className={className} {...props}>
      {children}
    </Paper>
  );
};

export default SectionContainer;
