import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { resetAll } from "src/app/actions";

export interface IState {
  drawerIsOpen: boolean;
  selectedContactId: string | undefined;
}

const initialState: IState = {
  drawerIsOpen: false,
  selectedContactId: undefined,
};

const contactSlice = createSlice({
  name: "contactInformation",
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setContactDrawerOpened: (state, action: PayloadAction<boolean>) => {
      state.drawerIsOpen = action.payload;
    },
    setSelectedContactId: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.selectedContactId = action.payload;
    },
  },
});

export const { setContactDrawerOpened, setSelectedContactId } =
  contactSlice.actions;
export default contactSlice.reducer;
