import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { resetAll } from "src/app/actions";

export interface IState {
  conversationArchiveListDrawerIsOpen: boolean;
  selectedConversationArchiveId: string | undefined;
}

const initialState: IState = {
  conversationArchiveListDrawerIsOpen: false,
  selectedConversationArchiveId: undefined,
};

const conversationArchiveDrawerSlice = createSlice({
  name: "conversationArchiveDrawer",
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setConversationArchiveDrawerIsOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.conversationArchiveListDrawerIsOpen = action.payload;
    },
    setSelectedConversationArchiveId: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.selectedConversationArchiveId = action.payload;
    },
  },
});

export const {
  setConversationArchiveDrawerIsOpen,
  setSelectedConversationArchiveId,
} = conversationArchiveDrawerSlice.actions;
export default conversationArchiveDrawerSlice.reducer;
