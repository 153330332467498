import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { resetAll } from "src/app/actions";

export interface IState {
  drawerIsOpen: boolean;
}

const initialState: IState = {
  drawerIsOpen: false,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setSettingsDrawer: (state, action: PayloadAction<boolean>) => {
      state.drawerIsOpen = action.payload;
    },
  },
});

export const { setSettingsDrawer } = settingsSlice.actions;
export default settingsSlice.reducer;
